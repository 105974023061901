<template>
  <app-modal-copy-business
    v-if="
      business.business_status_id == BUSINESS_STATUS.CREATED ||
      business.business_status_id == BUSINESS_STATUS.AMENDED
    "
    :show="showModalCopyBusiness"
    @copy-business="copyBusiness"
    @show="showModalCopyBusiness = $event"
    show_info="support_detail"
  />
  <div class="lg:w-1/2 w-full mb-5">
    <div class="flex space-x-3">
      <h1 class="text-2xl font-bold">
        {{ $t("business.support_detail.title") }}
      </h1>
      <app-text-tooltip 
           :text="$t('business.structure.copy_from_existing_business')" >
        <app-icon 
          v-if="showCopyBusinessIcon"
          name="ClipboardCopyIcon"
          class="h-9 w-6 text-gray-300 cursor-pointer hover:text-gray-600"
          @click="showModalCopyBusiness = true"
          
        />
      </app-text-tooltip>
    </div>
    <p class="mt-4">
      {{ $t("business.support_detail.description") }}
    </p>
  </div>
  <div class="w-full">
    <form
      @submit.prevent="submitBusinessSupportDetails"
      v-if="business.support_detail != undefined"
    >
      <div class="flex flex-col lg:flex-row lg:w-full w-full mb-5 flex-initial">
        <app-form-input
          id="business.support_detail.statement_descriptor"
          name="business.support_detail.statement_descriptor"
          type="text"
          class="lg:w-7/12"
          :placeholder="$t('business.support_detail.business_name_placeholder')"
          :labelText="$t('business.support_detail.statement_descriptor')"
          v-model="business.support_detail.statement_descriptor"
          :errorMessage="errors.statement_descriptor"
          @onblur="onStatementDescriptorFocus = false"
          @onfocus="onStatementDescriptorFocus = true"
          :disabled="!editMode"
        />
        <div
          v-if="onStatementDescriptorFocus"
          class="
            flex-none
            bg-gray-100
            h-full
            rounded-lg
            py-3
            px-2
            text-gray-500
            lg:w-4/12
            w-full
            lg:ml-10
            ml-0
            mt-3
            lg:mt-3
            text-sm
          "
        >
          {{ $t("business.support_detail.statement_descriptor_info") }}
        </div>
      </div>

      <div class="flex flex-col lg:flex-row lg:w-full w-full mb-5">
        <app-form-input
          id="business.support_detail.short_descriptor"
          name="business.support_detail.short_descriptor"
          type="text"
          :maxlength="10"
          class="lg:w-7/12"
          :placeholder="$t('business.support_detail.business_placeholder')"
          :labelText="$t('business.support_detail.shortened_descriptor')"
          v-model="business.support_detail.short_descriptor"
          :errorMessage="errors.short_descriptor"
          @onblur="onShortenedDescriptorFocus = false"
          @onfocus="onShortenedDescriptorFocus = true"
          :disabled="!editMode"
        />
        <div
          v-if="onShortenedDescriptorFocus"
          class="
            flex-none
            bg-gray-100
            h-full
            rounded-lg
            py-3
            px-2
            text-gray-500
            lg:w-4/12
            w-full
            lg:ml-10
            ml-0
            mt-2
            lg:mt-3
            text-sm
          "
        >
          {{ $t("business.support_detail.shortened_descriptor_info") }}
        </div>
      </div>

      <div class="flex flex-col lg:w-7/12 w-full mb-5">
        <app-form-input
          id="business.support_detail.support_email"
          name="business.support_detail.support_email"
          type="email"
          :placeholder="$t('business.support_detail.email')"
          :labelText="$t('business.support_detail.support_email')"
          v-model="business.support_detail.support_email"
          :errorMessage="errors.support_email"
          :disabled="!editMode"
        />
      </div>

      <app-form-telephone-no
        name="business.support_detail.support_tel_no"
        :labelText="$t('business.support_detail.phone_no')"
        class="lg:w-7/12 w-full mb-10"
        v-model="business.support_detail.support_tel_no"
        :errorMessage="errors.support_tel_no"
        :disabled="!editMode"
      />

      <app-button
        v-if="editMode"
        type="submit"
        :loading="loading"
        class="lg:w-7/12 w-full"
        :showProceedIcon="true"
        >{{ $t("general.next") }}</app-button
      >
    </form>
  </div>
</template>

<script>
import BUSINESS_STATUS from "@/utils/const/business_status";
export default {
  data() {
    return {
      BUSINESS_STATUS: BUSINESS_STATUS,
      showModalCopyBusiness: false,
      emptyErrors: {
        statement_descriptor: [],
        short_descriptor: [],
        support_tel_no: [],
        support_email: [],
      },
      onStatementDescriptorFocus: false,
      onShortenedDescriptorFocus: false,
    };
  },

  mounted() {
    if (this.business.id == null) {
      this.$router.push({ path: "/business" });
      return;
    }
  },

  computed: {
    loading() {
      return this.$store.getters["businessStore/loading"];
    },

    business() {
      return this.$store.getters["businessStore/business"];
    },
    all_business() {
      return this.$store.getters["businessStore/all_business"];
    },
    errors() {
      return this.$store.getters["businessStore/errors"] ?? this.emptyErrors;
    },
    showCopyBusinessIcon() {
      let statusBusinessAllow = [
        BUSINESS_STATUS.CREATED,
        BUSINESS_STATUS.AMENDED,
      ];

      return (
        this.all_business?.length > 0 &&
        statusBusinessAllow.includes(this.business.business_status_id)
      );
    },
    editMode() {
      return (
        this.business.business_status_id == BUSINESS_STATUS.CREATED ||
        this.business.business_status_id == BUSINESS_STATUS.AMENDED
      );
    },
  },

  methods: {
    copyBusiness(business) {
      let support_detail = business.support_detail;

      support_detail.id = this.business?.support_detail?.id ?? null;
      support_detail.business_id = this.business?.id ?? null;
      support_detail.statement_descriptor = business.support_detail.statement_descriptor;
      support_detail.short_descriptor = business.support_detail.short_descriptor;
      support_detail.support_email = business.support_detail.support_email;
      support_detail.support_tel_no = business.support_detail.support_tel_no;

      this.business.support_detail = support_detail;
    },

    async submitBusinessSupportDetails() {
      this.resetState();

      const businessSupportDetails = await this.$store.dispatch(
        "businessStore/createOrUpdateBusinessSupportDetails",
        this.business.support_detail
      );

      if (businessSupportDetails == null) return;

      this.$router.push({ path: "/business/bank-details" });
    },
    resetState() {
      this.$store.commit("businessStore/resetState", {
        errors: this.emptyErrors,
        message: null,
      });
    },
  },
};
</script>